/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ampverse-logo-small': {
    width: 16,
    height: 16,
    viewBox: '0 0 263 61',
    data: '<path pid="0" _fill="#06161c" d="M54.23.12l9.64 16.396h12.733l-9.568 16.386L48.146.117H35.637L.66 60.023h54.96L44.973 41.918H32.262l9.568-16.386 19.9 34.49h10.496L107.205.118 54.23.12z"/><path pid="1" _fill="#6d29f5" d="M133.486 60.025h-15.012L153.41.203h15.01l-34.934 59.822zM249.116.173h-53.357v59.799h13.258v-46.61h40.024v16.703s-19.796.014-24.719.014c-7.04 0-12.657 6.049-12.667 13.147h37.461a13.175 13.175 0 0013.177-13.177V13.36a13.174 13.174 0 00-8.133-12.182 13.174 13.174 0 00-5.044-1.004z"/><script/>'
  }
})
