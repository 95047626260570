/* eslint-disable */
require('./ampverse-logo-small')
require('./ampverse-logo')
require('./arrow-left')
require('./check')
require('./chevron-down')
require('./chevron-right')
require('./circle-exclamation')
require('./circle-question')
require('./circle-video-btn')
require('./close-menu-icon')
require('./collapse-minus')
require('./collapse-plus')
require('./copy')
require('./creative-image')
require('./creative-video')
require('./creative-zip')
require('./creative')
require('./dialog-bubbles')
require('./discord-auth-icon')
require('./discord-icon')
require('./discord-plain-icon')
require('./dots')
require('./download')
require('./earth')
require('./edit')
require('./error-icon')
require('./extension')
require('./eye-close')
require('./eye')
require('./facebook-icon')
require('./folder')
require('./friends')
require('./gear')
require('./heart')
require('./help-icon')
require('./home-page-vector')
require('./home')
require('./jonekiri-logo')
require('./level-0')
require('./level-1')
require('./level-2')
require('./level-3')
require('./level-4')
require('./level-5')
require('./link-arrow')
require('./link')
require('./list')
require('./logout')
require('./medium-icon')
require('./menu-icon')
require('./minus')
require('./pie-chart')
require('./play')
require('./plus-circle')
require('./plus')
require('./quarter-screen')
require('./refresh-loop')
require('./refresh')
require('./search-icon')
require('./small-grid')
require('./speaker')
require('./star-referrals')
require('./star')
require('./streammoney-logo')
require('./streamo-big')
require('./streamo-small')
require('./table-filter-icon')
require('./task-checked')
require('./trovo-icon')
require('./twitch-icon')
require('./twitter-icon')
require('./uplify-logo')
require('./uplify-plus-pareto-logo')
require('./uplify+trovo-logo')
require('./user')
require('./vk-icon')
require('./vkplay-icon')
require('./wallet')
require('./wrench-gear')
require('./x_x')
require('./x')
require('./yandex-icon')
require('./youtube-icon')
