import { Domain } from 'lib/types/domain'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import messages from './locales'

Vue.use(VueI18n)

const pluralizationRules: VueI18n.PluralizationRulesMap = {
  ru: function (choice, choicesLength) {
    if (choice === 0) {
      return 0
    }

    const teen = choice > 10 && choice < 20
    const endsWithOne = choice % 10 === 1

    if (choicesLength < 4) {
      return !teen && endsWithOne ? 1 : 2
    }
    if (!teen && endsWithOne) {
      return 1
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2
    }

    return choicesLength < 4 ? 2 : 3
  },
}

if (!localStorage.getItem('locale')) {
  const browserLocale = window.navigator.language
  let defaultAppLocale = process.env.VUE_APP_DEFAULT_LOCALE

  if (
    !browserLocale.includes('pt') &&
    window.location.origin === Domain.PARETO
  ) {
    defaultAppLocale = 'pt'
  }
  else if (
    !browserLocale.includes('ru') &&
    window.location.origin === Domain.STREAMMONEY
  ) {
    defaultAppLocale = 'ru'
  }
  else if (
    !browserLocale.indexOf('es') &&
    window.location.origin === Domain.GAMING_PARTNERS
  ) {
    defaultAppLocale = 'es'
  }
  else if (
    !browserLocale.indexOf('en') &&
    window.location.origin === Domain.AMPVERSE
  ) {
    defaultAppLocale = 'en'
  }

  localStorage.setItem('locale', defaultAppLocale)
}

export const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || process.env.VUE_APP_DEFAULT_LOCALE,
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE,
  messages,
  pluralizationRules,
})
